@font-face {
  font-family: 'feelio';
  src: url('../font/feelio.eot?13826464');
  src: url('../font/feelio.eot?13826464#iefix') format('embedded-opentype'),
       url('../font/feelio.woff2?13826464') format('woff2'),
       url('../font/feelio.woff?13826464') format('woff'),
       url('../font/feelio.ttf?13826464') format('truetype'),
       url('../font/feelio.svg?13826464#feelio') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'feelio';
    src: url('../font/feelio.svg?13826464#feelio') format('svg');
  }
}
*/
[class^="af-"]:before, [class*=" af-"]:before {
  font-family: "feelio";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.af-menu:before { content: '\e800'; } /* '' */
.af-cancel:before { content: '\e801'; } /* '' */
.af-facebook:before { content: '\e802'; } /* '' */
.af-mail:before { content: '\e803'; } /* '' */
.af-camera:before { content: '\e804'; } /* '' */
.af-instagram:before { content: '\e805'; } /* '' */
.af-cog:before { content: '\e806'; } /* '' */
.af-star:before { content: '\e807'; } /* '' */
.af-help:before { content: '\e808'; } /* '' */
.af-pencil:before { content: '\e809'; } /* '' */
.af-info:before { content: '\e80a'; } /* '' */
.af-success:before { content: '\e80b'; } /* '' */
.af-component-3:before { content: '\e80f'; } /* '' */
.af-heart:before { content: '\e814'; } /* '' */
.af-heart-chat:before { content: '\e815'; } /* '' */
.af-hidden-pass:before { content: '\e817'; } /* '' */
.af-messages:before { content: '\e81a'; } /* '' */
.af-profile:before { content: '\e81b'; } /* '' */
.af-search:before { content: '\e81c'; } /* '' */
.af-warning:before { content: '\e81d'; } /* '' */
.af-yes:before { content: '\e81e'; } /* '' */
.af-arrow-down-small:before { content: '\e81f'; } /* '' */
.af-arrow-right:before { content: '\e820'; } /* '' */
.af-arrow-right-small:before { content: '\e821'; } /* '' */
.af-bell:before { content: '\e822'; } /* '' */
.af-clock:before { content: '\e824'; } /* '' */
.af-close:before { content: '\e825'; } /* '' */
.af-comments:before { content: '\e827'; } /* '' */
.af-arrow-up-small:before { content: '\e828'; } /* '' */
.af-check-empty:before { content: '\f096'; } /* '' */
.af-variant:before { content: '\f0e8'; } /* '' */
.af-smile:before { content: '\f118'; } /* '' */
.af-check-minus:before { content: '\f146'; } /* '' */
.af-check-ok:before { content: '\f14a'; } /* '' */
.af-youtube-play:before { content: '\f16a'; } /* '' */
.af-google:before { content: '\f1a0'; } /* '' */
